import {
  decodePDFRawStream, PDFArray, PDFDict, PDFDocument, PDFName, PDFStream,
} from 'pdf-lib';

export const extractRawAttachments = (pdfDoc) => {
  if (!pdfDoc.catalog.has(PDFName.of('Names'))) return []
  const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict)

  if (!Names.has(PDFName.of('EmbeddedFiles'))) return []

  // make EmbeddedFiles mutable
  let EmbeddedFiles = Names.lookup(PDFName.of('EmbeddedFiles'), PDFDict)

  // when there's no /Name, but there are /Kids, take the first of /Kids instead
  if (!EmbeddedFiles.has(PDFName.of('Names')) && EmbeddedFiles.has(PDFName.of('Kids')))
    EmbeddedFiles = EmbeddedFiles.lookup(PDFName.of('Kids'), PDFArray).lookup(0)

  if (!EmbeddedFiles.has(PDFName.of('Names'))) return []
  const EFNames = EmbeddedFiles.lookup(PDFName.of('Names'), PDFArray)

  const rawAttachments = []
  for (let idx = 0, len = EFNames.size(); idx < len; idx += 2) {
    const fileName = EFNames.lookup(idx);
    const fileSpec = EFNames.lookup(idx + 1, PDFDict)
    rawAttachments.push({ fileName, fileSpec })
  }

  return rawAttachments
}

const extractAttachments = (pdfDoc) => {
  const rawAttachments = extractRawAttachments(pdfDoc);
  return rawAttachments.map(({ fileName, fileSpec }) => {
    const stream = fileSpec
      .lookup(PDFName.of('EF'), PDFDict)
      .lookup(PDFName.of('F'), PDFStream);
    return {
      name: fileName.decodeText(),
      data: decodePDFRawStream(stream).decode(),
    };
  });
};

const checkPDF = async (file, callback) => {
  const reader = new FileReader();
  const promise = new Promise((resolve, reject) => {
    reader.onload = async () => {
      const pdfDoc = await PDFDocument.load(new Uint8Array(reader.result));

      const attachments = extractAttachments(pdfDoc);
      attachments.forEach((attachment) => {
        if (attachment.name.toLowerCase().endsWith('.xml') || attachment.name.toLowerCase().includes('zugferd')) {
          resolve(attachment.data);
        }
      });
      resolve(null);
    }

    reader.onerror = (error) => {
      reject(error);
    }

    reader.readAsArrayBuffer(file);
  });

  return promise;
}

export default checkPDF;
