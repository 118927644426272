import React from 'react';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { githubGist } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

const Error = ({ message, xml }) => {
  return (
    <>
      {!xml ?
        <div className="error-message">{message}</div>
        :
        <SyntaxHighlighter
          style={githubGist}
          customStyle={{
            position: 'fixed',
            top: 16,
            left: 16,
            right: 16,
            bottom: 16,
            borderRadius: 5,
            overflow: 'auto',
            fontWeight: 'bold',
          }}
          language={'xml'}
        >
          {xml}
        </SyntaxHighlighter>
      }
    </>
  )
}

export default Error
