import React from 'react';

import { format2decimals } from '../lib/utils';

const Payment = ({ payment, supplier, duedate, paymentinformation, paymentnote, currency }) => {

  const isdue = duedate && duedate.some(due => due.calculationpercent || due.date);

  return (
    <>
      <div className="payment">
        {duedate && <div className="duedate">
          <div>{isdue ? 'Fällig' : ''}</div>
          <div>{duedate.map(due => {
            return due.calculationpercent ?
              <div key={due.date}>am {due.date}:  {format2decimals(due.calculationpercent)}% Skonto {due.basisamount ? `von ${format2decimals(due.basisamount, currency)}` : ''}</div>
              :
              due.date ? <div key={due.date}>am {due.date}: netto</div> : null
          })}</div>
        </div>}
        {paymentinformation && <div className="paymentinformation">
          <div>Zahlungsinfo</div>
          <div>{paymentinformation}</div>
        </div>}
        {paymentnote && <div className="paymentnote">
          <div>Zahlungsnotiz</div>
          <div>{paymentnote}</div>
        </div>}
      </div>

      {payment.map((pay, index) => <div key={index} className="payments">
        {pay.reference && <div className="reference">
          <div>Referenz</div>
          <div>{pay.reference}</div>
        </div>}
        {pay.iban && <div className="iban">
          <div>IBAN</div>
          <div>{pay.iban}</div>
        </div>}
        {pay.bic && <div className="bic">
          <div>BIC</div>
          <div>{pay.bic}</div>
        </div>}
        {pay.id && <div className="id">
          <div>Verwendungszweck</div>
          <div>{pay.id}</div>
        </div>}
      </div>)}

      <div className="payment">
        {supplier.iln && <div className="iln">
          <div>ILN</div>
          <div>{supplier.iln}</div>
        </div>}
      </div>
    </>
  )
}

export default Payment
