import React from 'react';

const Supplier = ({ supplier }) => {
  return (
    <div className="supplier">
      {supplier.number && <div className="supplierno">
        <div>{supplier.number}</div>
      </div>}
      <div className="suppliername">{supplier.name}</div>
      <div>{supplier.street}</div>
      <div>{supplier.country} {supplier.zip} {supplier.city}</div>
    </div>
  )
}

export default Supplier
