import React from 'react';

const Notes = ({ notes }) => {
  let lines = notes;
  if (Array.isArray(notes)) {
    lines = notes[0];
  }
  return (
    <>
      {lines && lines.length > 0 && lines[0] && <div className="notes">
        <div className="notes-title">Notizen</div>
        {Array.isArray(lines) ?
          lines?.map((note = '', index) => <div className="noteline" key={index}>
              {typeof note === 'string' && note?.split('\n').map(line => <div key={line}>{line}</div>)}
            </div>
          )
          :
          <div className="noteline">
            {typeof lines === 'string' && lines?.split('\n').map(line => <div key={line}>{line}</div>)}
          </div>
      }
      </div>}
    </>
  )
}

export default Notes
